<template>
  <div class="news">
    <div class="news-time">2020-2021年</div>
    <div class="news-title">知产侵权涉互联网案件持续增加</div>
    <div class="news-numbers">
      <div class="item" v-for="(item,index) in config" :key="index">
        <div class="num">{{item.num}}</div>
        <div class="text">{{item.text}}</div>
      </div>
    </div>
    <div class="news-dec">
      互联网环境下的侵权行为具有隐蔽性与复杂性，导致权利人维权难度进一步加大，因此，在创新成果保护、著作权保护和公平竞争保护等领域，各级法院在过去一年均加大了涉及新技术、新业态和平台经济的知识产权司法保护力度。
    </div>
  </div>
</template>

<script>
export default {
  name: "module-news",
  data(){
    return Object.freeze({
      config:[
        {num:'360,489',text:'著作权案件'},
        {num:'124,716',text:'商标案件'},
        {num:'31,618',text:'专利案件'},
        {num:'4,015',text:'技术合同'}
      ]
    })
  }
}
</script>

<style scoped>
.news{
  height: 508px;
  background-image: url("~@/assets/images/news_bg_image.png");
  background-size: cover;
  padding: 72px 80px;
  display: flex;
  flex-direction: column;
}
.news-time{
  height: 44px;
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  color: #165DFF;
}
.news-title{
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  color: #1D2129;
}
.news-numbers{
  margin: 48px 0;
  display: flex;
  gap:64px;
  width: 695px;
  height: 76px;
}
.news-dec{

  width: 504px;
  height: 96px;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}
.num{
  font-family: DINPro;
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  color: #165DFF;
}
.text{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: #1D2129;
}
</style>