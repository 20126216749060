<template>
  <div class="about" id="about">
    <div class="content">
      <h2>关于我们</h2>
      <h1>互联网知识产权存证领跑者</h1>
      <h3>正信存证云是通过区块链技术搭建司法公证平台，旨在为企事业单位解决司法公证“最后一公里”的问题。平台以区块链技术为核心，融合了大数据、人工智能、元宇宙等先进技术手段，自主研发知识产权云服务智能化系统。平台目前整合了专业服务团队包括律师、专利代理师以及软件开发人才，充分挖掘福建省内公证机构、国内各大知名律所、事务所等的具体需求，建构起全流程一站式的服务体系，可提供从企业日常研发到知识产权产生以及企业的知识产权运用转化和知识产权维护、保护的一站式综合服务， 助力企业知识产权运营体系建设，提升企业知识产权竞争力。</h3>
      <div class="item" v-for="(item,index) in config" :key="index">
        <div>
          <img src="@/assets/images/dd.png" height="28px">
        </div>
        <div>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-about",
  data(){
    return Object.freeze({
      config:[
        {text:'创建于2019年，目前已经服务过多家500强企业'},
        {text:'所有配套软件及工具均拥有独立知识产权'},
        {text:'对于特殊应用场景我们可以提供一定的免费技术支持'},
        {text:'对于特殊应用场景我们可以提供一定的免费技术支持'},
      ]
    })
  }
}
</script>

<style scoped>
.about{
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url("~@/assets/images/about_bg_BG.png");
  display: flex;
  padding: 72px 80px;
}
.content{
  width: 612px;
}
h1{
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  color: #1D2129;
  margin: 12px 0 48px 0;
}
h2{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1D2129;
}
h3{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
  margin-bottom: 72px;
}

.item{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}
.text {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1D2129;
}
</style>