<template>
  <div class="data">
    <div>联合维权</div>
    <div class="data-title">从维权方式选择变化特征来看，<br/>
      采取多元渠道解决纠纷能力进一步提升</div>
    <div class="data-dec">
      2022年企业选择维权措施比例增长最多的是向法院提请诉前责令停止侵权行为，通过仲裁、调解方式解决以及发出要求停止侵权的律师函，分别较上年提升5.5、5.0、2.9 个百分点。
    </div>
    <div style="display: flex;justify-content: space-between">
      <div class="data-numbers">
        <div class="item" v-for="(item,index) in config" :key="index">
          <div class="num">{{item.num}}</div>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
      <div class="data-about">
        <div class="about-item">
          <div class="title">关于维权方式</div>
          <div class="text">建议采用发函-协商-调解-仲裁-法院的递进方式处理。</div>
        </div>
        <div class="about-item">
          <div class="title">关于判赔</div>
          <div class="text">专利侵权案件中高判赔比例呈上升态势，近两年高额赔偿专利侵权案件比例增长较为明显。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-data",
  data(){
    return Object.freeze({
      config:[
        {num:'30.6%',text:'发出要求停止侵权的律师函'},
        {num:'30.8%',text:'向法院提起诉讼'},
        {num:'28.9%',text:'自行与侵权方协商解决'},
        {num:'21.5%',text:'请求行政处理'},
        {num:'18.9%',text:'通过仲裁调解方式解决'},
        {num:'13.0%',text:'向法院提请诉前责令停止侵权行为'},
        {num:'30.4%',text:'其他'},

      ]
    })
  }
}
</script>

<style scoped>
.data{
  height: 588px;
  background-image: url("~@/assets/images/data_bg_image.png");
  padding: 72px 80px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
}

.data-title{
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  color: #1D2129;
  margin: 12px 0 48px 0;
}
.data-numbers{
  display: grid;
  width: 900px;
  height: 176px;
  grid-column-gap: 40px;
  grid-row-gap: 24px;
}
.data-numbers .item:nth-child(n + 4) {
  grid-row: 2;
}

.data-dec{
  width: 504px;
  height: 96px;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}
.num{
  font-family: DINPro;
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  color: #165DFF;
}
.text{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: #1D2129;
}
.data-about{
  display: flex;
  justify-content: space-around;
  gap: 24px;
  margin-left: 32px;
}
.about-item{
  width: 200px;
  height: 176px;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  gap: 8px;
  flex-grow: 1;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border: 2px solid ;
  border-image: linear-gradient(131deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) 2;
  backdrop-filter: blur(15px);
  z-index: 0;
}

.about-item .title{
  font-family: Alimama ShuHeiTi;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  display: flex;
  color: #165DFF;
}
.about-item .text{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color: #1D2129;
}


</style>