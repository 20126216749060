<template>
  <div class="ai">
    <div class="content">
      <div class="ai-title">
        <h3>AI智能查找侵权线索</h3>
        <h1>我们能监测什么？</h1>
        <h2>90%以上准确率</h2>
      </div>
      <div class="ai-box">
        <div class="item" v-for="(item,index) in config1" :key="index">
          <img :src="item.img" height="200px" style="object-fit: cover">
          <div class="item-text">{{item.text}}</div>
          <div class="item-title">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-ai",
  data(){
    return Object.freeze(
        {
          config1:[
            {text:'商标/摄影作品/平面设计图/产品设计图等',title:'图像作品',img:require('@/assets/images/ai1.png')},
            {text:'音乐/电影录音戏曲等',title:'影音作品',img:require('@/assets/images/ai2.png')},
            {text:'小说/剧本歌词/论文等',title:'文字作品',img:require('@/assets/images/ai3.png')},
            {text:'线下店铺未授权商标店招广告等',title:'现实店铺作品',img:require('@/assets/images/ai4.png')}
          ],
        }
    )
  }
}
</script>

<style scoped>


.ai{
  width: 100%;
  padding: 72px 80px;
  background-image: url("@/assets/images/ai_bg_image.png");
  background-size: cover;
  background-position: center ;

}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ai-title{
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ai-title h3{
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  font-weight: normal;
  color: #1D2129;
}
.ai-title h1{
  font-family: Alimama ShuHeiTi;
}
.ai-title h2{
  font-family: Alimama ShuHeiTi;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  color: #165DFF;
}

.ai-box{
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
  gap: 32px;
  margin-top: 32px;

}
.ai-box .item{
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 0px 0px 8px 8px;
  background: #FFFFFF;
  width: 295px;
  justify-content: space-between;
}
.ai-box .item-title{
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1D2129;
  padding: 24px;
  border-top: 1px solid #E5E6EB;
}
.ai-box .item-text{
  font-size: 16px;
  padding: 24px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}

</style>