<template>
  <div class="adv" id="adv">
    <div class="content">
      <div class="adv-title">
        <h3>侵权取证·产品优势</h3>
        <h1>联合公证处进行证据保全</h1>
        <h2>《民事诉讼法》以及相关司法解释都赋予了公证证据以证据之王的地位，除非有相反证据足以推翻，由公证机构经法定程序公证证明的法律事实，法院应当作为认定事实的依据，这也是其它取证途径所无法比拟的的优势。</h2>
      </div>
      <div class="adv-box">
        <div class="item" v-for="(item,index) in config1" :key="index">
          <img :src="item.img" height="285px" width="405px">
          <div class="item-title">{{item.title}}</div>
          <div class="item-text">{{item.text}}</div>
        </div>
      </div>
      <div class="adv-tem">
        <div class="item" v-for="(item,index) in config2" :key="index">
         <div>
           <img :src="item.img" width="80px">
         </div>
          <div style="position:relative;">
            <div class="item-title" style="margin-bottom: 16px">{{item.title}}</div>
            <div class="hr" :style="{backgroundColor:item.color}"></div>
            <div class="item-text">{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-advantages",
  data(){
    return Object.freeze(
        {
          config1:[
            {text:'长期与律师团队合作，有丰富互联网侵权取证经验',title:'专业取证团队',img:require('@/assets/images/adv1.png')},
            {text:'覆盖手机终端(android/ios)、电脑PC端、 执法仪等多种设备，适合不同取证场景需求',title:'丰富的取证工具',img:require('@/assets/images/adv2.png')},
            {text:'通过发律师函、协商、仲裁或调解、起诉等方式责令侵权方停止侵权行为，甚至赔偿经济损失',title:'多元的纠纷解决能力',img:require('@/assets/images/adv3.png')}
          ],
          config2:[
            {text:'强大的综合存证取证能力！具备网页、文件、录像、邮件、远程屏录等功能，海量证据、复杂证据、多人账号管理好轻松！',title:'电脑客户端',img:require('@/assets/images/adv4.png'),color:'#165DFF'},
            {text:'登录即可开始存证及证据管理！',title:'网页',img:require('@/assets/images/adv5.png'),color: '#50D0EA'},
            {text:'口袋里的证据专家！各式各样的取证功能，随时随地都能取证。证据握在手，轻松化解纠纷！',title:'移动客户端',img:require('@/assets/images/adv6.png'),color:'#FFB255'},
            {text:'存证赋能，带来原生使用体验！通过API标准接口，将云的存证能力快速接入到应用方，并赋予其第三方证据保全电子数据确认函的能力。',title:'AP标准接口',img:require('@/assets/images/adv7.png'),color:'#FF8555'},

          ]
        }
    )
  }
}
</script>

<style scoped>


.adv{
  width: 100%;
  padding: 72px 80px;
  background-image: url("@/assets/images/adv_bg_image.png");
  background-size: cover;
  background-position: center ;

}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adv-title{
  text-align: center;
}
.adv-title h3{
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  font-weight: normal;
  color: #1D2129;
}
.adv-title h1{
  font-family: Alimama ShuHeiTi;
}
.adv-title h2{
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}
.adv-box{
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 0;

}
.adv-box .item{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 18px;
}
.adv-box .item-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #1D2129;
}
.adv-box .item-text{
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #1D2129;
  width: 405px;
}
.adv-tem{
  /*height: 336px; // change: 去掉高度*/
  border-radius: 8px;
  padding: 32px;
  opacity: 1;
  display: flex;
  background: linear-gradient(105deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-sizing: border-box;
  border: 2px solid #FFFFFF;
  backdrop-filter: blur(10px);
  flex-wrap: wrap;
  gap: 32px;
  margin: 0 16px;
}
.adv-tem .item{
  display: flex;
  flex:1 580px;
  align-items: center;
}

.adv-tem .item-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #1D2129;
}
.adv-tem .item-text{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}

.hr{
  position: absolute;
  left: 0px;
  top: 20px;
  width: 100px;
  height: 8px;
  z-index: -1;
}

</style>