<template>
  <div class="scenes">
    <div class="content">
      <div class="scenes-title">
        <h1>应用场景</h1>
      </div>
      <div class="scenes-box">
        <div class="item" v-for="(item,index) in config" :key="index">
          <img :src="item.img" width="80px">
          <div class="item-title">{{item.title}}</div>
          <div class="item-text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-scenes",
  data(){
    return Object.freeze(
        {
          config:[
            {text:'互联网时代信息传播极快，没有预先登记的情况下，数字作品被侵权的概率很高，而且维权成本很高，正信存证服务提供了数字作品，包括图片，视频，音频，文字，代码等数字作品的所有权登记服务，让原创作品快速生成司法认可的电子数据存证证明，保护数字作品版权。',title:'数字作品登记',img:require('@/assets/images/s1.png')},
            {text:'电子证明，交易凭证，电子合同，电子票据等数据凭证等不容易自证， 很难流通，很难验真等，正信存证服务联合公证处为电子凭证提供永久性存证，验证服务，易于数据价值流通。',title:'电子凭证存证',img:require('@/assets/images/s2.png')},
            {text:'网络购物行为，交易行为存在大量的细节性数据，随若时间的推移这些数据会容易遗失，当产生纠纷时就很难串接起来，正信存证服务可以为在线交易提供公正客观司法认可的存证服务，为线上数据分析提供双保险。',title:'在线交易存证',img:require('@/assets/images/s3.png')},
            {text:'互联网服务大量纠纷在于平台和商家是否就服务限制性条件提前明确告知用户，包括限制性条件，使用范围，服务协议，隐私保护条款等，正信存证服务提供了具备可信时间戳，公证中立，确保司法认可，帮助商家或者用户维护自己合法权益。',title:'服务须知存证',img:require('@/assets/images/s4.png')},
          ]
        }
    )
  }
}
</script>

<style scoped>


.scenes{
  width: 100%;
  height: 778px;
  padding: 72px 80px;
  background-image: url("@/assets/images/scens_bg_image.png");
  background-size: cover;
  background-position: center;
;
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.scenes-title{
  text-align: center;
}
.scenes-title h1{
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  letter-spacing: 0px;
  /* 文本/text-1 */
  color: #FFFFFF;
}
.scenes-box{
  height: 233px;
  display: grid;
  width: 100%;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  z-index: 0;
}
.item{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 18px;
  background: #1E2131;
  background-image: url("~@/assets/images/scens_item_bgimage.png");
  background-size: cover;
}
.item-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #FFFFFF;
}
.item-text{
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #FFFFFF;
}
.content {

}

</style>