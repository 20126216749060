<template>
  <div class="point" id="point">
    <div class="content">
      <div class="item" v-for="(item,index) in config" :key="index">
        <div>
          <div class="num">{{`0${index + 1}`}}</div>
        </div>
        <div>
          <div class="title">{{item.title}}</div>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-point",
  data(){
    return Object.freeze({
      config:[
        {title:'全面检索',text:'自定义创建任务，可覆盖各大常用平台，快速地检索网络材料，Ai比对目标侵权物'},
        {title:'智能算法',text:'通过算法优化对排查标的物在互联网平台上可能出现的权重进行排序，智能筛选'},
        {title:'自动截图存证',text:'对于命中的目标可按设定是否自动截图并做公证存证'},
        {title:'专业分类',text:'根据客户设定自动对电商图片侵权、影音视频媒体侵权等进行分门别类'},
      ]
    })
  }
}
</script>

<style scoped>
.point{
  height: 605px;
  background-size: cover;
  background-image: url("~@/assets/images/point_bg_image.png");
  position: relative;
}
.content{
  position: absolute;
  left: 0px;
  top: 32px;
  width: 822px;
  height: 504px;
  padding: 0px 80px;
}
.num{
  font-family: CROSSOVER;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
  color: #165DFF;
}
.item{
  display: flex;
  height: 126px;
  gap: 24px;
  padding: 32px 0;
}
.title{
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #1D2129;
  margin-bottom: 10px;
}
.text {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}
</style>