<template>
  <div class="container">
    <PageHeader :is-scroll="isScroll" :active-index="activeIndex"/>
    <ModuleHome />
    <ModuleTechnology />
    <ModuleScenes />
    <ModuleWay />
    <ModuleNews />
    <ModuleAdvantages />
    <ModulePoint/>
    <ModuleAi />
    <ModuleData />
    <ModulePlan />
    <ModuleDefense />
    <ModuleAbout />
    <PageFooter />
    <div class="back-to-top" @click="backToTop()" v-if="isScroll">
      ↑
    </div>
  </div>
</template>

<script>

import PageHeader from "@/components/page-header";
import ModuleHome from "@/components/module-home";
import ModuleTechnology from "@/components/module-technology";
import ModuleScenes from "@/components/module-scenes";
import ModuleNews from "@/components/module-news";
import ModuleAdvantages from "@/components/module-advantages";
import ModulePoint from "@/components/module-point";
import ModuleAi from "@/components/module-ai";
import ModuleData from "@/components/module-data";
import ModulePlan from "@/components/module-plan";
import ModuleDefense from "@/components/module-defense";
import ModuleAbout from "@/components/module-about";
import PageFooter from "@/components/page-footer";
import ModuleWay from "@/components/module-way";

export default {
  name: 'App',
  components: {
    ModuleWay,
    PageFooter,
    ModuleAbout,
    ModuleDefense,
    ModulePlan,
    ModuleData,
    ModuleAi,
    ModulePoint, ModuleAdvantages, ModuleNews, ModuleScenes, ModuleTechnology, ModuleHome, PageHeader},
  data(){
    return {
      isScroll: false,
      activeIndex: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    let ids = ['home','technology','adv','point','plan','about']
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const currId = entry.target.id
          const currIndex = ids.findIndex(id => id == currId)
          this.activeIndex = currIndex
        }
      });

    },{threshold:0.6});

    ids.forEach((id) => {
      intersectionObserver.observe(document.querySelector(`#${id}`))
    })

  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.isScroll = window.scrollY > 0;
    },
    backToTop() {
      window.scrollTo({top:0})
    }
  }
}
</script>

<style>

.container {
  width: 100%;
}

.back-to-top{
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 120px;
  background-color: white;
  text-align: center;
  line-height: 30px;
  z-index: 999;
}


</style>
