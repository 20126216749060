<template>
  <div class="way" id="way">
    <div class="content">
      <div class="way-title">
        <h1>多种存证方式，满足各种场景需求</h1>
      </div>
      <div class="way-box">
        <div class="item" v-for="(item,index) in config" :key="index">
          <img :src="item.img" width="80px">
          <div class="item-title">{{item.title}}</div>
          <div class="item-text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-way",
  data(){
    return Object.freeze(
        {
          config:[
            {text:'',title:'拍照存证',img:require('@/assets/images/way1.png')},
            {text:'',title:'录像存证',img:require('@/assets/images/way2.png')},
            {text:'',title:'文件存证',img:require('@/assets/images/way3.png')},
            {text:'',title:'网页存证',img:require('@/assets/images/way4.png')},
            {text:'',title:'脱敏hash存证',img:require('@/assets/images/way5.png')},
            {text:'',title:'终端录制存证',img:require('@/assets/images/way6.png')}
          ]
        }
    )
  }
}
</script>

<style scoped>


.way{
  width: 100%;
  height: 621px;
  padding: 72px 80px;
  background-image: url("@/assets/images/way_bg_image.png");
  background-size: cover;
;
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.way-title{
  text-align: center;
}
.way-title h1{
  font-size: 36px;
  line-height: 44px;
}
.way-box{
  margin-top: 32px;
  display: grid;
  width: 100%;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 40px 0px #DEE7F1;
}
.item{
  height: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 18px;
}
.item-title{
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #1D2129;
}
.item-text{
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #1D2129;
}
.content {

}

</style>