<template>
  <div class="technology" id="technology">
    <div class="content">
      <div class="technology-title">
        <h3>区块链存证 · 产品优势</h3>
        <h1>正信存证云采用长安区块链作为底层核心框架</h1>
        <h2>数字信任经济的价值流动新引擎</h2>
      </div>
      <div class="technology-box">
        <div class="item" v-for="(item,index) in config" :key="index">
          <img :src="item.img" width="80px">
          <div class="item-title">{{item.title}}</div>
          <div class="item-text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module-technology",
  data(){
    return Object.freeze(
        {
          config:[
            {text:'数据采用分布式账本保存，并由各有公信力的权威节点共同见证。',title:'权威节点见证',img:require('@/assets/images/t1.png')},
            {text:'让区块链具备高数据安全、高性能低延时以及集群灾备高可用设计，使得存证流转得到安全可靠的保证。',title:'安全可靠的技术',img:require('@/assets/images/t2.png')},
            {text:'业界领先的电子合同、金融、版权、租赁等应用场景和商业实践，联动生态合作伙伴共同服务百万中小企业。',title:'丰富应用场景',img:require('@/assets/images/t3.png')},
            {text:'采用完全分布式的架构，对数据加密，计算和交互都在节点之间独立完成，无中心控割节点，真实保障不可慕改。',title:'真实不可篡改',img:require('@/assets/images/t4.png')},
            {text:'保证数据最小化利用，所有计算均在加密或脱敏之后进行计算， 最大程度的保障个人隐私数据安全。',title:'合规隐私保护',img:require('@/assets/images/t5.png')},
            {text:'商户及合作伙伴可以通过API 以及SDK一站式接入，提供全流程存证，出证，维权服务。',title:'一站式接入',img:require('@/assets/images/t6.png')}
          ]
        }
    )
  }
}
</script>

<style scoped>


.technology{
  width: 100%;
  height: 778px;
  padding: 72px 80px;
  background-image: url("@/assets/images/technology_bg_image.png");
  background-size: cover;
;
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.technology-title{
  text-align: center;
}
.technology-title h3{
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  font-weight: normal;
  color: #1D2129;
}
.technology-title h2{
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  color: #1D2129;
}
.technology-box{
  height: 233px;
  display: grid;
  width: 100%;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;

  z-index: 0;
}
.item{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 18px;


  background: #FFFFFF;
}
.item-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #1D2129;
}
.item-text{
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #1D2129;
}
.content {

}

</style>