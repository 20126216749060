<template>
  <div class="header" :class="{ scrolled: isScroll }">
    <div class="header-logo">
      <img src="@/assets/images/logo_image.png" height="40px">
    </div>
    <ul class="header-menu">
      <li class="menu-item" v-for="(item,index) in menuConfig" :key="index" :class="[index === currIndex ? 'active':'']">
        <a  @click="jump(item,index)">
          <span style="cursor: pointer">{{item.title}}</span>
        </a>
      </li>
    </ul>
    <div class="header-btn" @click="handleLogin">
      <span>登录</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-header",
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  methods:{
    handleLogin () {
      //todo 处理登录
      window.open("https://admin.r.aper.kim", "_blank");
    },
    jump(item,index){
      const dom = window.document.querySelector(item.href)
      this.currIndex = index
      // 减一个header高度
      window.scrollTo({
        top: dom.offsetTop - 78,
      })
    }
  },
  watch: {
    activeIndex(newValue){
      this.currIndex = newValue
    }
  },
  data(){
    return {
      menuConfig:[
          {title:'首页',href:'#home'},
          {title:'区块链存证',href:'#technology'},
          {title:'侵权取证',href:'#adv'},
          {title:'特色服务',href:'#point'},
          {title:'解决方案',href:'#plan'},
          {title:'关于我们',href:'#about'}
        ,],
      currIndex: this.activeIndex
    }
  },
}
</script>

<style scoped>
.scrolled{
  background-color: #f0f8ff;
}
.menu-item:hover{
  border-bottom: 3px solid cornflowerblue;
}
.active{
  border-bottom: 3px solid cornflowerblue;
}
.header{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 80px;
  z-index: 10;
}
.header-menu{
  width: 528px;
  height: 78px;
  line-height: 78px;
  /* 自动布局 */
  display: flex;
  padding: 0px;
  gap: 32px;
}
.menu-item{
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;

  color: #1D2129;
}
.header-logo{
  margin-right: auto;
}
.header-btn{
  cursor: pointer;
  width: 72px;
  height: 40px;
  border-radius: 2px;
  color: #FFFFFF;
  padding: 8px 20px;
  background: linear-gradient(119deg, #165DFF 0%, #168EFF 99%);
  margin-left: 64px;
}
a{
  color: #1D2129;
}
</style>
