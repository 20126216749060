<template>
  <div class="home" id="home">
    <div class="swiper-wrap">
      <swiper :modules="modules" style="height: 100%;"
              :space-between="50"
              :autoplay="{delay:5000,autoplay:true}"
              :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="(item, index) in items" :key="index">
          <img :src="item" width="100%">
        </swiper-slide>
      </swiper>
    </div>
    <div class="home-footer">
      <div class="footer-item">
        <img src="@/assets/images/dd.png" height="28px">
        <div>
          区块链保障平台技术中立
        </div>
      </div>
      <div class="footer-item">
        <img src="@/assets/images/dd.png" height="28px">
        <div>
          专业的取证团队及工具
        </div>
      </div>
      <div class="footer-item">
        <img src="@/assets/images/dd.png" height="28px">
        <div>
          司法机构一站式服务
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
export default {
  name: "module-home",
  components:{
    Swiper,
    SwiperSlide
  },
  data(){
    return{
      modules:[Autoplay, Pagination],
      items: [
          require('@/assets/images/banner1.png'),
          require('@/assets/images/banner2.png')
      ] // 轮播项内容数组
    }
  },
}
</script>

<style scoped>
.swiper-wrap{
  height: 100%;
}
.home {
  width: 100%;
  height: 662px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.home-footer {
  position: absolute;
  left: 0px;
  z-index: 7;
  bottom: 0;
  width: 100%;
  height: 112px;
  /* 自动布局 */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 64px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: inset 0px 2px 1px 0px rgba(255, 255, 255, 0.5);
}

.footer-item{
  color: #4E5969;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  gap: 16px
}
.btn{
  width: 130px;
  height: 44px;
  position: absolute;
  cursor: pointer;
  z-index: 20;
  left: 66px;
  top: 430px;

}

</style>
<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 160px !important;
}
.swiper-pagination-bullet{
  width: 36px;
  border-radius: 4px;
  height: 4px;
  background-color: white;
  opacity: 1;
}
.swiper-pagination-bullet-active{
  background-color: #1D2129;
}
</style>
