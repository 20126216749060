<template>
  <div class="footer">
   <div class="content">
     <div>
       <img src="@/assets/images/logo_image_White.png" height="40px">
       <div class="text">为企业的知识产权插上法律和科技的翅膀，<br /> 使得企业的研发创新能力自由安全地在市场中流通并获得增值！<br /> 保护知识产权就是保护创新。</div>
<!--       <div class="way">联系方式</div>-->
<!--       <div class="tel">0592-8265807</div>-->
     </div>
     <div class="navigation">
       <div class="title">导航</div>
       <ul>
         <li v-for="(item,index) in navConfig" :key=index>
           <a @click="jump(item)" style="cursor:pointer;">
             {{item.text}}
           </a>
         </li>
       </ul>
     </div>
     <div class="service">
       <div class="title">服务项目</div>
       <ul>
         <li v-for="(item,index) in serviceConfig" :key=index>
           <a :href="item.href">
             {{item.text}}
           </a>
         </li>
       </ul>
     </div>
     <div class="download title" @click="handleDownload">
       <span>工具下载</span>
     </div>
   </div>
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/" target="_blank">Copyright©2024闽ICP备2024047001号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-footer",
  methods:{
    handleDownload () {
      //todo 下载
    },
    jump(item){
      const dom = window.document.querySelector(item.href)
      // 减一个header高度
      window.scrollTo({
        top: dom.offsetTop - 78,
      })
    }
  },
  data(){
    return Object.freeze({
      //todo 配置跳转
      serviceConfig:[
        {text:'事前：基本存证',href:''},
        {text:'事中（发生侵权）：委托取证',href:''},
        {text:'事后：侵权委托处理',href:''}
      ],
      navConfig:[
        {text:'首页',href:'#home'},
        {text:'知识产权存证',href:'#technology'},
        {text:'侵权取证',href:'#adv'},
        {text:'特色服务',href:'#point'},
        {text:'解决方案',href:'#plan'},
        {text:'关于我们',href:'#about'}
      ]
    })
  },
}
</script>

<style scoped>
.footer{
  width: 100%;
  background: #1D2129;
  padding: 72px 80px 0 80px;
}
.content{
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #86909C;
  padding-bottom: 72px;
}
.title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 32px;
  color: #FFFFFF;
}
a {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #86909C;
  margin-bottom: 24px;
  display: block;
}
.download {
  cursor: pointer;
}
.text{
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color: #86909C;
  margin-top: 20px;
  margin-bottom: 95px;
}
.way{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #86909C;
  margin-bottom: 32px;
}
.tel{
  font-family: DINPro;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  color: #FFFFFF;
}
.copyright{
  height: 72px;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 72px;
  color: #86909C;
  text-align: center;
}
</style>
