<template>
  <div class="defense">
    <div class="content">
      <div class="defense-title">
        <h3>溯源防伪</h3>
        <h1>对产品生产周期关键数据进行全流程存证，并作为溯源节点数据对外展示</h1>
      </div>
      <div class="defense-box">
        <div class="item" v-for="(item,index) in config1" :key="index">
            <div class="bg"></div>
           <div style="color: #FFFFFF;padding: 24px">
             <div class="item-title">{{ item.title }}</div>
             <div class="item-dec">{{ item.dec }}</div>
           </div>
          <div>
            <div class="item-text">
              {{item.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "module-defense",
  data() {
    return Object.freeze(
        {
          config1: [
            {text:'传统扫码核验造假技术门槛及成本太低， 普通大众难以识别核验模块本身是否合法。',dec: '兼具便携及权威性能', title: '公证机构核验入口'},
            {text:'在溯源结果上扩展个性化营销内容，比如全息三维产品展示、数字展厅、官方购买入口等。',dec: '整合数字化营销功能', title: '立体营销',},
            {text:'采用nfc电子标签作为防伪标签，提高造假整体技术成本。物联网接入，溯源过程更加高效。',dec: '采用加密卡，更具安全性', title: 'NFC电子标签',}
          ],
        }
    )
  }
}
</script>

<style scoped>

.defense {
  width: 100%;
  padding: 72px 80px;
  background-image: url("@/assets/images/defense_bg_image.png");
  background-size: cover;
  background-position: center;

}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.defense-title {
  text-align: center;
}

.defense-title h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #1D2129;
}

.defense-title h1 {
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  color: #1D2129;
  margin: 12px 0 32px 0;
}

.defense-title h2 {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}

.defense-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
  gap: 16px;

}

.defense-box .item {
  height: 208px;
  flex: 1 416px;
  min-width: 416px;
  border-radius: 8px;
  background-color: #165DFF;
  z-index: 1;
  position: relative;
}
.defense-box .item .bg{
  background-color: #165DFF;
  height: 207px;
  width: 207px;
  position: absolute;
  right: 0;
  background-image: url("~@/assets/images/defense_item_bg_image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}

.defense-box .item-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.defense-box .item-dec {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
.defense .item-text{
  width: 392px;
  height: 96px;
  border-radius: 0px 8px 0px 8px;
  opacity: 1;
  background-color: #FFFFFF;
  position: absolute;
  bottom: 0;
  padding: 24px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #4E5969;
}


</style>
